
import { defineComponent, onMounted, reactive, toRaw } from 'vue'

import SCalendar from '@/common/components/SCalendar/index.vue'
import SelectedDateRange from '@/modules/HistoryNew/components/SelectedDateRange/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import iClose from '@/assets/icons/Close.svg'

import { EXPRESS_STATUS_OPTIONS } from '@/core/constants/Express.constants'

import { useHistory } from '@/modules/HistoryNew/composable/useHistory'
import { ExpressHistoryFilters } from '@/core/classes/ExpressHistoryFilters.class'

export default defineComponent({
    components: {
        's-calendar': SCalendar,
        'selected-date-range': SelectedDateRange,
        's-button': SButton,
        'icon-close': iClose,
    },
    emits: ['close'],
    setup(_, { emit }) {
        const { resetFilters, setExpressFilters, FTLFiltersState, loadData } = useHistory()

        const state = reactive(new ExpressHistoryFilters())

        onMounted(() => {
            state.date_range = FTLFiltersState.date_range
            state.status = FTLFiltersState.status
        })

        const emitClose = () => {
            emit('close')
        }

        const resetFiltersHandler = () => {
            state.date_range = {
                date_from: null,
                date_to: null,
            }
            state.status = null

            resetFilters()
            emitClose()
            loadData()
        }

        const applyFilters = () => {
            emitClose()
            setExpressFilters(toRaw(state))
            loadData()
        }

        return {
            state,
            //
            emitClose,
            resetFiltersHandler,
            applyFilters,
            //
            EXPRESS_STATUS_OPTIONS,
        }
    },
})
